import { DATE_FORMATS, getDateFormat } from '@/components/utils/dateUtils'
import React from 'react'

export default class Utilities {
	///////////////////////////////////////////
	// Array methods
	///////////////////////////////////////////
	static compact(array) {
		return array.filter(x => x)
	}

	static combineArray(array, newValues) {
		return [...array, ...newValues]
	}

	static unshiftArray(array, newValues) {
		return [...newValues, ...array]
	}

	static removeArray(array, removedValues) {
		return array.filter(a => !removedValues.includes(a))
	}

	static flatten(arrayOfArrays) {
		return [].concat.apply([], arrayOfArrays)
	}

	static groupBy(xs, keys) {
		const _keys = [].concat(keys)
		return xs.reduce((rv, x) => {
			const key = _keys.map(k => x[k]).join(' @@ ')
			rv[key] = rv[key] ?? []
			rv[key].push(x)
			return rv
		}, {})
	}

	static countBy(xs, keys) {
		return xs.reduce((rv, x) => {
			const key = []
				.concat(keys)
				.map(k => x[k])
				.join(' @@ ')
			rv[key] = (rv[key] || 0) + 1
			return rv
		}, {})
	}

	static forceToArray(elem) {
		return elem ? Object.values(elem) : null
	}

	static joinBy(a1, a2, key) {
		return Utilities.uniqt([...a1.map(o => o[key]), ...a2.map(o => o[key])])
			.sort()
			.map(k => ({ ...a1[k], ...a2[k] }))
	}

	static median(array) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (!array || !array.length || array.length == 0) {
			return null
		}
		const numbers = array
			.filter(x => !isNaN(x))
			.map(x => parseFloat(x))
			.sort(
				(a, b) =>
					(typeof a === 'number' ? a : Number.NEGATIVE_INFINITY) -
					(typeof b === 'number' ? b : Number.NEGATIVE_INFINITY),
			)
		const middle = Math.floor(numbers.length / 2)
		const isEven = numbers.length % 2 === 0
		return isEven
			? (numbers[middle] + numbers[middle - 1]) / 2
			: numbers[middle]
	}

	static minMax(array) {
		const filtered = array.filter(x => !isNaN(x))
		const min = Math.min.apply(Math, filtered)
		const max = Math.max.apply(Math, filtered)
		return [min, max]
	}

	static move(array, oldIndex, newIndex) {
		const _array = array.slice()
		if (newIndex >= _array.length) {
			let k = newIndex - _array.length
			while (k-- + 1) {
				_array.push(undefined)
			}
		}
		_array.splice(newIndex, 0, _array.splice(oldIndex, 1)[0])
		return _array
	}

	static replaceAt(array, index, value) {
		const _array = array.slice()
		_array[index] = value
		return _array
	}

	static safeCombine(array = [], newValues = []) {
		return Utilities.uniqt(Utilities.combineArray(array, newValues))
	}

	static safeRemove(array = [], removedValues = []) {
		return Utilities.uniqt(Utilities.removeArray(array, removedValues))
	}

	static sort(array) {
		return array.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
	}

	static sortKey(arrayOfObjects, key) {
		return arrayOfObjects.sort((a, b) =>
			a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0,
		)
	}

	static sortObjectsByNameField(arrayOfObjects, key = 'display_name') {
		return arrayOfObjects.sort((a, b) => {
			if (!a || !a[key]) {
				return -1
			} else if (!b || !b[key]) {
				return 1
			}

			const aName = a[key].toLowerCase().replace(/^the\s*/, '')
			const bName = b[key].toLowerCase().replace(/^the\s*/, '')

			if (aName < bName) {
				return -1
			} else if (aName > bName) {
				return 1
			}
			return 0
		})
	}

	static minByKey(arrayOfObjects, key) {
		return Utilities.compact(Utilities.sortKey(arrayOfObjects, key))[0]
	}

	static maxByKey(arrayOfObjects, key) {
		const sorted = Utilities.compact(Utilities.sortKey(arrayOfObjects, key))
		return sorted[sorted.length - 1]
	}

	static sortString(array) {
		return array.sort((a, b) =>
			a.toUpperCase() < b.toUpperCase()
				? -1
				: a.toUpperCase() > b.toUpperCase()
				? 1
				: 0,
		)
	}

	static spliceArray(array, removedValues, keys) {
		removedValues.map(
			// Legacy eqeqeq -- resolve when possible
			// eslint-disable-next-line eqeqeq
			r => (array = array.filter(a => !keys.every(k => a[k] == r[k]))),
		)
		return Utilities.flatten(array)
	}

	static sum(array) {
		return array.reduce((a, b) => a + b, 0)
	}

	static average(array) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		const _array = array.filter(x => x != null && !isNaN(x))
		const sum = _array.reduce((a, b) => a + parseFloat(b), 0)
		const l = _array.length
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		return l == 0 ? null : sum / l
	}

	static sumObjectArray(array, key) {
		return array.reduce(
			(sum, val, idx, arr) => sum + (parseFloat(arr[idx][key]) || 0),
			0,
		)
	}

	static toKey(x, keys) {
		return keys.map(k => x[k]).join(' @@ ')
	}

	static toMap(ary, keys) {
		if (!ary || !ary.length) {
			return {}
		}
		return ary.reduce((o, x) => {
			const key = []
				.concat(keys)
				.map(k => x[k])
				.join(' @@ ')
			o[key] = x
			return o
		}, {})
	}

	static toOptions(xs) {
		return (xs || []).map(x => ({ label: x, value: x }))
	}

	static uniq(array) {
		return [...new Set(array)]
	}

	static uniqt(array) {
		return Utilities.compact(Utilities.uniq(array))
	}

	static uniqKey(arrayOfObjects, key) {
		const uniq = [],
			filtered = {}
		arrayOfObjects.forEach(item => {
			if (!filtered[item[key]]) {
				filtered[item[key]] = item
				uniq.push(item)
			}
		})
		return uniq
	}

	static weightBy(arrayOfObjects, key, weight) {
		const numer = arrayOfObjects.reduce(
			(a, b) =>
				a +
				(!b[key] || isNaN(b[key]) || !b[weight] || isNaN(b[weight])
					? 0
					: parseFloat(b[key]) * parseFloat(b[weight])),
			0,
		)
		const denom = arrayOfObjects.reduce(
			(a, b) =>
				a +
				(!b[key] || isNaN(b[key]) || !b[weight] || isNaN(b[weight])
					? 0
					: parseFloat(b[weight])),
			0,
		)
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		return denom != 0 ? numer / denom : null
	}

	static arraysEqual(a, b) {
		if (a === b) return true
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (a == null || b == null) return false
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (a.length != b.length) return false

		for (let i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false
		}
		return true
	}

	static conjunctify(idx, count) {
		return count > 1 && idx + 1 < count
			? idx + 2 < count
				? ', '
				: ' and '
			: ''
	}

	static toHash(arrayOfPairs) {
		return arrayOfPairs.reduce((o, [k, v]) => {
			o[k] = v
			return o
		}, {})
	}

	static alwaysArray(stringOrArray) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		return stringOrArray.constructor == Array ? stringOrArray : [stringOrArray]
	}

	///////////////////////////////////////////
	// Date methods
	///////////////////////////////////////////

	static getQuarter(date = new Date()) {
		return Math.floor(date.getMonth() / 3) + 1
	}

	static year(date) {
		return (date && date.toString('yyyy')) || null
	}

	static getDateFromHash(hash) {
		const ms = parseInt(hash) - 24 * 60 * 60 * 1000
		return this.monthEnd(new Date(ms)).toString('yyyy-MM-dd')
	}

	// Returns Date if date is Date, otherwise standard date string
	static endOfQuarter(date) {
		const _date = Date.parse(date) || new Date()
		const qtrEndDate = new Date(
			_date.getFullYear(),
			Utilities.getQuarter(_date) * 3,
			0,
		)
		return date && typeof date.getMonth === 'function'
			? qtrEndDate
			: qtrEndDate.toString('yyyy-MM-dd')
	}

	static monthEnd(date = new Date()) {
		const parsed = Date.parse(date)
		return (
			parsed &&
			new Date(parsed.getFullYear(), parsed.getMonth() + 1, 0).toString(
				'yyyy-MM-dd',
			)
		)
	}

	static quarterEnd(date = new Date()) {
		const parsed = Date.parse(date)
		return (
			parsed &&
			new Date(
				parsed.getFullYear(),
				Utilities.getQuarter(parsed) * 3,
				0,
			).toString('yyyy-MM-dd')
		)
	}

	static isQuarterEnd(date = new Date()) {
		return date && Utilities.safeDate(date) === Utilities.quarterEnd(date)
	}

	static halfYearEnd(date = new Date()) {
		const parsed = Date.parse(date)
		if (!parsed) return null
		const month = [5, 11].find(idx => idx >= parsed.getMonth())
		return Utilities.monthEnd(new Date(parsed.getFullYear(), month))
	}

	static yearEnd(date = new Date()) {
		const parsed = Date.parse(date)
		return (
			parsed && new Date(parsed.getFullYear(), 11, 31).toString('yyyy-MM-dd')
		)
	}

	static previousQuarter(date = new Date()) {
		const parsed = Date.parse(date)
		return parsed && Utilities.endOfQuarter(parsed.addMonths(-3))
	}

	static nextQuarter(date = new Date()) {
		const parsed = Date.parse(date)
		return parsed && Utilities.endOfQuarter(parsed.addMonths(3))
	}

	static isValidDate(dateString) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (!dateString || dateString.length == 0) return false
		const regEx = /^\d{4}-\d{2}-\d{2}$/
		if (!dateString.match(regEx)) return false // Invalid format
		const d = new Date(dateString)
		if (!d.getTime() && d.getTime() !== 0) return false // Invalid date
		return d.toISOString().slice(0, 10) === dateString
	}

	static lastYear(date = new Date()) {
		return new Date(date.getFullYear(), 0, 0)
	}

	static lastQuarter(date = new Date()) {
		return new Date(date.getFullYear(), (Utilities.getQuarter(date) - 1) * 3, 0)
	}

	static lastMonth(date = new Date()) {
		return new Date(date.getFullYear(), date.getMonth(date), 0)
	}

	static lastYearString(date, pattern = 'yyyy-MM-dd') {
		const parsed = Date.parse(date) || new Date()
		return Utilities.lastYear(parsed).toString(pattern)
	}

	static lastQuarterString(date, pattern = 'yyyy-MM-dd') {
		const parsed = Date.parse(date) || new Date()
		return Utilities.lastQuarter(parsed).toString(pattern)
	}

	static lastMonthString(date, pattern = 'yyyy-MM-dd') {
		const parsed = Date.parse(date) || new Date()
		return Utilities.lastMonth(parsed).toString(pattern)
	}

	static sortObjectsByDateField(a, b, fieldName = 'date') {
		if (!a[fieldName] || !b[fieldName]) {
			return !a[fieldName] ? (!b[fieldName] ? 0 : -1) : 1
		}
		const aDate = Date.parse(a[fieldName])
		const bDate = Date.parse(b[fieldName])
		const A = aDate && aDate.valueOf(),
			B = bDate && bDate.valueOf()
		return A < B ? -1 : A > B ? 1 : 0
	}

	static safeDate(dateString, pattern = 'yyyy-MM-dd') {
		return Date.parse(dateString)
			? Date.parse(dateString).toString(pattern)
			: ''
	}

	static sortDates(arrayOfDates, desc = false) {
		const descValue = desc ? 1 : -1
		return arrayOfDates.sort((a, b) => {
			const A = Date.parse(a).valueOf(),
				B = Date.parse(b).valueOf()
			return A < B ? -1 * descValue : A > B ? 1 * descValue : 0
		})
	}

	static dateString(date, pattern = 'yyyy-MM-dd') {
		return date && Date.parse(date) && Date.parse(date).toString(pattern)
	}

	static priorMonth(date = new Date(), pattern = 'yyyy-MM-dd') {
		return Utilities.monthsAgo(date, 1, pattern)
	}

	static priorQuarter(date = new Date()) {
		return Utilities.quarterEnd(Utilities.monthsAgo(date, 3))
	}

	static datesEqual(date1, date2) {
		const parsed1 = Date.parse(date1)
		const parsed2 = Date.parse(date2)
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		return parsed1 && parsed2 && parsed1.compareTo(parsed2) == 0
	}

	static priorYear(date = new Date(), pattern = 'yyyy-MM-dd') {
		const d = Date.parse(date)
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (d == null) {
			return null
		}
		return new Date(d.getFullYear() - 1, d.getMonth(), d.getDate()).toString(
			pattern,
		)
	}

	static yearsFrom(date = new Date(), years, pattern = 'yyyy-MM-dd') {
		const d = Date.parse(date)
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (d == null) {
			return null
		}
		return new Date(
			d.getFullYear() + years,
			d.getMonth(),
			d.getDate(),
		).toString(pattern)
	}

	static monthsAgo(date = new Date(), months = 1, pattern = 'yyyy-MM-dd') {
		const d = Date.parse(date)
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (d == null) {
			return null
		}
		return new Date(d.getFullYear(), d.getMonth() - months + 1, 0).toString(
			pattern,
		)
	}

	static threeMonthsAgo(date = new Date(), pattern = 'yyyy-MM-dd') {
		return Utilities.monthsAgo(date, 3, pattern)
	}

	static maxDate(dates) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (dates == null) {
			return null
		}
		const parsed = Utilities.compact(dates.map(d => Date.parse(d)))
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (parsed.length == 0) {
			return null
		}
		return Utilities.dateString(
			// Legacy eqeqeq -- resolve when possible
			// eslint-disable-next-line eqeqeq
			parsed.reduce((max, d) => (d && d.compareTo(max) == 1 ? d : max)),
		)
	}

	static minDate(dates) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (dates == null) {
			return null
		}
		const parsed = Utilities.compact(dates.map(d => Date.parse(d)))
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (parsed.length == 0) {
			return null
		}
		return Utilities.dateString(
			// Legacy eqeqeq -- resolve when possible
			// eslint-disable-next-line eqeqeq
			parsed.reduce((max, d) => (d && d.compareTo(max) == -1 ? d : max)),
		)
	}

	static greaterThan(dateStr1, dateStr2) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (Date.parse(dateStr1) == null) {
			return null
		}
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (Date.parse(dateStr2) == null) {
			return true
		}
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		return Date.parse(dateStr1).compareTo(Date.parse(dateStr2)) == 1
	}

	static atLeast(dateStr1, dateStr2) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (Date.parse(dateStr1) == null) {
			return null
		}
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (Date.parse(dateStr2) == null) {
			return true
		}
		return Date.parse(dateStr1).compareTo(Date.parse(dateStr2)) > -1
	}

	static quarterLabel(dateStr, alwaysShowYear = false) {
		const date = Date.parse(dateStr)
		const qtr = parseInt(date.getMonth() / 3) + 1
		return `Q${qtr}${
			// Legacy eqeqeq -- resolve when possible
			// eslint-disable-next-line eqeqeq
			qtr == 1 || alwaysShowYear ? `\n${date.getFullYear()}` : ''
		}`
	}

	static quarterLabelWithEntryOrExit = reportingDate => {
		const fullYear = new Date(reportingDate).getFullYear()
		return fullYear === 1900
			? "'at entry'"
			: fullYear === 2200
			? "'at exit'"
			: Utilities.quarterLabel(reportingDate, true)
	}

	static ordinalDate(date) {
		const parsed = Date.parse(date)
		return `${parsed.toString('MMMM')} ${Utilities.toOrdinal(
			parsed.toString('dd'),
		)}`
	}

	static daysFrom(date) {
		const timeDiff =
			(Date.parse(date).getTime() - new Date().getTime()) /
			(1000 * 60 * 60 * 24)
		return !isNaN(timeDiff) && Math.ceil(timeDiff).toFixed(0)
	}

	static yearsBetween(start, end) {
		const startTime = Date.parse(start) && Date.parse(start).getTime()
		const endTime = Date.parse(end) && Date.parse(end).getTime()
		return (
			startTime &&
			endTime &&
			(endTime - startTime) / (365.25 * 24 * 60 * 60 * 1000)
		)
	}

	static reportingDate(dateStr) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		return dateStr == Utilities.quarterEnd(dateStr)
			? dateStr
			: Utilities.lastQuarterString(dateStr)
	}

	static dateFromExcel(excelDate) {
		if (isNaN(excelDate)) {
			return excelDate
		} // For instances where is formatted as text date
		const date = new Date((excelDate - (25567 + 1)) * 86400 * 1000)
		return date.toString('yyyy-MM-dd')
	}

	///////////////////////////////////////////
	// String methods
	///////////////////////////////////////////

	static ellipsize(string, maxLength) {
		if (string && string.length > maxLength) {
			return `${string.slice(0, maxLength)}...`
		}
		return string
	}

	static paragraphs(string) {
		return string.split('\n').map(s => (
			<span key={s}>
				{s}
				<br />
			</span>
		))
	}

	static titleize(string) {
		return (
			(string &&
				string
					.toLowerCase()
					.replace(/(?:-|_)/g, ' ')
					.replace(/(?:^|\s)\S/g, m => m.toUpperCase())
					.trim()) ||
			''
		)
	}

	static toCamelCase(string) {
		if (!string) return string
		return (
			string.charAt(0).toLowerCase() +
			// Legacy -- resolve when possible
			// eslint-disable-next-line no-useless-escape
			string.slice(1).replace(/(\_\w)/g, x => x[1].toUpperCase())
		)
	}

	static camelCaseKeys(obj) {
		return Object.keys(obj).reduce((o, k) => {
			o[Utilities.toCamelCase(k)] = obj[k]
			return o
		}, {})
	}

	static snakeCaseKeys(obj) {
		return Object.keys(obj).reduce((o, k) => {
			o[Utilities.toSnakeCase(k)] = obj[k]
			return o
		}, {})
	}

	static toDashCase(string) {
		return string.split(' ').join('-').toLowerCase()
	}

	static toPascalCase(string = '') {
		return string
			.replace(/[-_]+/g, ' ')
			.replace(/[^\w\s]/g, '')
			.replace(
				/\s+(.)(\w+)/g,
				($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`,
			)
			.replace(/\s/g, '')
			.replace(/\w/, s => s.toUpperCase())
	}

	static toSnakeCase(string) {
		return string.replace(/([A-Z])/g, s => `_${s.toLowerCase()}`)
	}

	static toOrdinal(x) {
		const n = parseInt(x)
		const s = ['th', 'st', 'nd', 'rd']
		const v = n % 100
		return n + (s[(v - 20) % 10] || s[v] || s[0])
	}

	static toTitleCase(string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	static titleToSnakeCase(string) {
		return this.toSnakeCase(this.lcFirst(this.toPascalCase(string)))
	}

	static lcFirst(string) {
		return string.charAt(0).toLowerCase() + string.slice(1)
	}

	static ucWords(string) {
		return string && string.replace(/(?=^|\b)(\w)(\w+)/g, this.toTitleCase)
	}

	static basicPastTense(string) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		return string + (string.charAt(string.length - 1) == 'e' ? 'd' : 'ed')
	}

	static getNavLabel(net = true, date = null, newlineDate = false) {
		const label = net ? 'NAV' : 'Unrealized'
		if (date) {
			const formattedDate = Date.parse(date).toString(
				getDateFormat(DATE_FORMATS.MONTH_DAY_YEAR),
			)
			return `${label} at${newlineDate ? '\n' : ' '}${formattedDate}`
		}
		return label
	}

	static replaceRomanNumeralsWithIntegers(str, prepend = true) {
		if (!str) return str
		const romanMap = {
			I: 1,
			II: 2,
			III: 3,
			IV: 4,
			V: 5,
			VI: 6,
			VII: 7,
			VIII: 8,
			IX: 9,
			X: 10,
			XI: 11,
			XII: 12,
			XIII: 13,
			XIV: 14,
			XV: 15,
			XVI: 16,
			XVII: 17,
			XVIII: 18,
			XIX: 19,
			XX: 20,
			XXI: 21,
			XXII: 22,
			XXIII: 23,
			XXIV: 24,
			XXV: 25,
			XXVI: 26,
			XXVII: 27,
			XXVIII: 28,
			XXIX: 29,
			XXX: 30,
		}
		return str.replace(/\b[XVI]{1,6}\b/g, numeral => {
			const integer = romanMap[numeral]
			if (integer) return (prepend && integer < 10 ? '0' : '') + integer
			return numeral
		})
	}

	///////////////////////////////////////////
	// Currency methods
	///////////////////////////////////////////

	static safeFloat(value) {
		// Legacy -- resolve when possible
		// eslint-disable-next-line no-useless-escape
		const nonNumeric = new RegExp(/[^-?0-9\.]+/g)
		const stringVal = typeof value === 'string' && nonNumeric.test(value)
		const multiplier =
			typeof value === 'string' && value.includes('(') && value.includes(')')
				? -1
				: 1
		return stringVal
			? parseFloat(value.replace(nonNumeric, '')) * multiplier
			: value
	}

	///////////////////////////////////////////
	// Object methods
	///////////////////////////////////////////
	static except(obj, ...excludeKeys) {
		const o = { ...obj }
		excludeKeys.map(key => delete o[key])
		return o
	}

	static filterObj(obj, f) {
		return Object.keys(obj).reduce((rv, k) => {
			if (f(obj[k]) === true) {
				rv[k] = obj[k]
			}
			return rv
		}, {})
	}

	static get(obj, ...keys) {
		return keys.reduce(
			(o, x) => (typeof o === 'undefined' || o === null ? null : o[x]),
			obj,
		)
	}

	static isEmpty(obj) {
		return (
			!obj ||
			(Array.isArray(obj)
				? // Legacy eqeqeq -- resolve when possible
				  // eslint-disable-next-line eqeqeq
				  obj.length == 0
				: Object.keys(obj).length === 0 && obj.constructor === Object)
		)
	}

	static normalizeKeys(o) {
		return Object.keys(o).reduce((rv, k) => {
			// Legacy eqeqeq -- resolve when possible
			// eslint-disable-next-line eqeqeq
			rv[k.charAt(0) == '_' ? k.substring(1) : k] = o[k]
			return rv
		}, {})
	}

	static omit(obj, keys) {
		return Object.keys(obj).reduce((result, k) => {
			if (!keys.includes(k)) {
				result[k] = obj[k]
			}
			return result
		}, {})
	}

	static slice(obj, keys) {
		return [].concat(keys).reduce((o, k) => {
			if (k in obj) {
				o[k] = obj[k]
			}
			return o
		}, {})
	}

	static underlineKeys(o) {
		return Object.keys(o).reduce((rv, k) => {
			rv[`_${k}`] = o[k]
			return rv
		}, {})
	}

	static sortKeys(object) {
		const sortedObject = {}
		Object.keys(object)
			.sort()
			.map(key => {
				sortedObject[key] = object[key]
			})
		return sortedObject
	}

	static stringifyQueryParams(params) {
		return new URLSearchParams(params).toString()
	}

	///////////////////////////////////////////
	// Misc
	///////////////////////////////////////////

	static compareTo(a, b) {
		return a < b ? -1 : a > b ? 1 : 0
	}

	static present(value) {
		return value !== null && value !== undefined && value !== ''
	}

	static normalizeCoords(e) {
		const clickX = e.clientX - e.currentTarget.getBoundingClientRect().x
		const clickY = e.clientY - e.currentTarget.getBoundingClientRect().y
		return { x: clickX, y: clickY }
	}

	static closestNumber(number, array = []) {
		let curr = array[0] || number
		array.forEach(val => {
			if (Math.abs(number - val) < Math.abs(number - curr)) {
				curr = val
			}
		})
		return curr
	}

	static percentGrowth(prior, current) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		if (current - prior != 0 && Math.abs(prior) != 0) {
			return (current - prior) / parseFloat(Math.abs(prior))
		}
		return 0
	}

	// Weighted distance function
	static distance(p, p2, xWeight = 1, yWeight = 1) {
		const a = (p.x - p2.x) * xWeight
		const b = (p.y - p2.y) * yWeight
		return Math.sqrt(a * a + b * b)
	}

	// Gets the closest point from an array of points to an x,y coordinate set
	// Legacy -- resolve when possible
	// eslint-disable-next-line max-params
	static getClosestLinePoint(
		points,
		x,
		y,
		xDistanceLimit,
		yDistanceLimit,
		minMaxMatrix = null,
	) {
		const closest = Utilities.getClosestPoint(
			points,
			x,
			y,
			(minMaxMatrix && minMaxMatrix.xWeight) || 1,
			(minMaxMatrix && minMaxMatrix.yWeight) || 5,
			minMaxMatrix,
		)
		if (xDistanceLimit || yDistanceLimit) {
			if (
				(!xDistanceLimit || Math.abs(closest.x - x) <= xDistanceLimit) &&
				(!yDistanceLimit || Math.abs(closest.y - y) < yDistanceLimit)
			) {
				return closest
			}
		} else {
			return closest
		}
	}

	// Legacy -- resolve when possible
	// eslint-disable-next-line max-params
	static withinLimits(x, y, minX, minY, maxX, maxY) {
		return (
			(!minX || x > minX) &&
			(!minY || y > minY) &&
			(!maxX || x < maxX) &&
			(!maxY || y < maxY)
		)
	}

	// Legacy -- resolve when possible
	// eslint-disable-next-line max-params
	static getClosestPoint(
		points,
		x,
		y,
		xWeight = 1,
		yWeight = 1,
		minMaxMatrix = null,
	) {
		return points.reduce(
			(min, p) => {
				if (
					Utilities.distance({ x, y }, p, xWeight, yWeight) < min.d &&
					(!minMaxMatrix ||
						Utilities.withinLimits(
							p.x,
							p.y,
							minMaxMatrix.minX,
							minMaxMatrix.minY,
							minMaxMatrix.maxX,
							minMaxMatrix.maxY,
						))
				) {
					min.point = p
					min.d = Utilities.distance({ x, y }, p, xWeight, yWeight)
				}
				return min
			},
			{
				point: points[0],
				d: Utilities.distance({ x, y }, points[0], xWeight, yWeight),
			},
		).point
	}

	static approxEqualCoordinate(y1, y2, weight = 2) {
		return y1 && y2 && y1 - weight <= y2 && y1 + weight >= y2
	}

	static approxEqual(n1, n2) {
		return (
			n1 &&
			!isNaN(n1) &&
			n2 &&
			!isNaN(n2) &&
			// Legacy eqeqeq -- resolve when possible
			// eslint-disable-next-line eqeqeq
			parseFloat(n1).toFixed(5) == parseFloat(n2).toFixed(5)
		)
	}

	static isUuid(id) {
		// Legacy eqeqeq -- resolve when possible
		// eslint-disable-next-line eqeqeq
		return typeof id === 'string' && id.indexOf('-') != -1
	}

	static mode(arr, whenEqualItemPriority) {
		return arr.reduce(
			(current, item) => {
				const val = (current.numMapping[item] =
					(current.numMapping[item] || 0) + 1)
				if (val > current.greatestFreq) {
					current.greatestFreq = val
					current.mode = item
				} else if (whenEqualItemPriority && val === current.greatestFreq) {
					current.mode = whenEqualItemPriority(item, current.mode)
				}
				return current
			},
			{ mode: null, greatestFreq: -Infinity, numMapping: {} },
		).mode
	}

	static debug(message) {
		if (process.env.NODE_ENV !== 'production' && window && window.DEBUG) {
			if (typeof message === 'object') {
				console.log(JSON.parse(JSON.stringify(message)))
			} else {
				console.log(message)
			}
		}
	}

	static elementHasClassName(element, classNames = []) {
		if (element && element.className && typeof element.className === 'string') {
			return classNames.find(
				className => element.className.indexOf(className) !== -1,
			)
		}
	}

	static elementPathFind(element, comparator, maxDepth = 25) {
		if (!comparator || !element) {
			return
		}

		let index = 0
		while (element && index < maxDepth) {
			if (comparator(element)) {
				return element
			}
			element = element.parentElement
			index += 1
		}
	}

	static timeout(ms = 1000) {
		return new Promise(resolve => setTimeout(resolve, ms))
	}

	static filesizeString(size) {
		if (size === null || isNaN(size)) return ''
		const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
		const sizeDecimal = size / Math.pow(1024, i)
		const roundedSize = sizeDecimal.toFixed(1)
		return `${roundedSize}${['B', 'kB', 'MB', 'GB', 'TB'][i]}`
	}

	static removeUnsafeXMLChars(unsafe) {
		return unsafe.replace(/[<>&'"]/g, '')
	}

	static getSanitizedFileName(filename) {
		const disallowedChars = /[<>:"/\\|?*]/g
		return filename.replace(disallowedChars, '')
	}
}
